<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer/>

    <div class="mx-3"/>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>


    <!-- --->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-bell</v-icon>
          <v-badge
            color="red"
            overlap
            bordered
            class="mb-5"
            v-if="$auth.getNotificationsCount()"
          >
            <template v-slot:badge>
              <span>{{$auth.getNotificationsCount()}}</span>
            </template>

          </v-badge>

        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
        two-line
      >


        <template v-for="(item, index) in notifications">
          <v-list-item :key="`item-${index}`" :class="{'grey lighten-2':!item.read_at}"
                       @click="notificationClick(item)">
            <template>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="text-h1">{{item.title}}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span class="text-h2">{{item.body}}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text v-text="item.created_at"></v-list-item-action-text>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>

    <!-- -->


    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="logout">
          <v-icon>mdi-logout</v-icon>

          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Components
  import {VHover, VListItem} from 'vuetify/lib'

  // Utilities
  import {mapState, mapMutations} from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render(h) {
          return h(VHover, {
            scopedSlots: {
              default: ({hover}) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [
        // 'Mike John Responded to your email',
        // 'You have 5 new tasks',
        // 'You\'re now friends with Andrew',
        // 'Another Notification',
        // 'Another one',
      ],
      notificationCount: 1
    }),

    computed: {
      ...mapState(['drawer']),
    },
    created() {
      this.$authApi.get(`/notifications`).then(res => {
        this.notifications = res.data.data.data;
      })
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      logout() {
        this.$auth.destroyToken();
        this.$router.push('/auth/login');
      },
      notificationClick(item) {
       if (!item.read_at) {
          this.$authApi.get(`/notifications/${item.id}`).then(res => {
            this.$set(this.notifications, this.notifications.indexOf(item), res.data.data)
          });
          this.$auth.decrementNotificationCount();
       }
        switch (item.type) {
          case 'export-sheet-ready':
            this.$router.push('/subscribers').catch(err => {});
            break;
        }
      }
    },
  }
</script>
